import React, {useState} from 'react'
import Footer from '../components/Footer'
import Intro from '../components/Intro'
import Mission from '../components/Mission'
import Navbar from '../components/Navbar'
import Roadmap from '../components/Roadmap'
import Siderbar from '../components/Siderbar'
import { HomeContainer, HomeImg } from '../styles/home'
import homebg from '../images/BG2.jpg'
import Faq from '../components/Faq'

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>

            <Siderbar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle} />
            <Intro/>
            <Mission/>
            <Roadmap/>
            <Faq />
            <Footer/>
        </>
    )
}

export default Home
