import styled from "styled-components";
import { StyledClickable } from "../../styles/globalStyles";

export const RoadmapContainer = styled.div`
background: #0d0d0d;
flex-direction: column;
height: 1200px;
display: flex;
@media screen and (max-width: 768px) {
    height: 1300px;
}
`

export const Roadmapspace = styled.div`
height: 40px;
`

export const RoadmapTopic = styled.div`
flex-direction: column;
padding: 0 9%;
align-items: center;
text-align: center;
`

export const RoadmapH = styled.h1`
color: #fff;
font-size: 50px;
font-family: 'Bungee Shade', cursive;
`

export const RoadmapP = styled.p`
color: #fff;
font-size: 24px;
@media screen and (max-width: 768px) {
    font-size: 20px;
}
`
export const Roadmaplink = styled.a`
color: #fff;
font-size: 24px;
display: flex;
align-items: center;
@media screen and (max-width: 768px) {
    font-size: 20px;
}
`

export const RoadmapBlock = styled.div`
display: flex;
height: 200px;
`
export const RoadmapgifB = styled.div`
width: 25%;
padding: 50px 9%;
display: flex;
justify-content: center;
align-items: center;

`

export const Roadmapgif = styled.img`
width: 150px;
height: 150px;
display: flex;
object-fit: cover;
@media screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
}
`

export const RoadmapitemB = styled.div`
padding: 50px 7%;
width: 75%;

justify-content: center;
align-items: center;

`

export const Roadmapitem = styled.p`
color: #fff;
font-size: 24px;
@media screen and (max-width: 768px) {
    font-size: 14px;
}
`