import styled from "styled-components";

export const IntroContainer = styled.div`
color: #fff;
background: #0d0d0d;
display: flex;
height: 600px;
align-items: center;
position: relative;
padding: 10%;

@media screen and (max-width: 1024px) {
    padding: 15%;
}
@media screen and (max-width: 768px) {
    padding: 150px 0;
}
`
export const Introborder = styled.div`
color: #fff;

padding: 10px;
height: 400px;
width: 70%;

@media screen and (max-width: 768px) {
    width: 70%;
}
`

export const IntroContent = styled.div`
padding: 5%;
width: 70%;
flex-direction: column;
align-items: center;


margin: 0;
position: absolute;
top: 50%;
-ms-transform: translateY(-50%);
transform: translateY(-50%);

@media screen and (max-width: 1024px) {
    width: 50%;
}

@media screen and (max-width: 768px) {
    width: 70%;
}
`


export const IntroContentH1 = styled.h1`
color: #3F900D;
font-size: 64px;
font-family: 'Luckiest Guy', cursive;
width: 600px;
@media screen and (max-width: 768px) {
    font-size: 32px;
    width: 300px;
}

`
export const IntroContentP = styled.p`
color: #fff;
margin-top: 24px;
font-size: 24px;
max-width: 600px;

@media screen and (max-width: 768px) {
    font-size: 24px;
}
`

export const IntroSlogan = styled.p`
color: #FF0000;
font-size: 36px;
font-family: 'Luckiest Guy', cursive;

@media screen and (max-width: 768px) {
    font-size: 26px;
    width: 300px;
}
`

export const Introborder2 = styled.div`
color: #fff;

width: 30%;
display: flex;
justify-content: center;
align-items: center;

@media screen and (max-width: 768px) {
    width: 50%;
}

`
export const IntroImg = styled.img`
width: 300px;
height: 300px;

display: flex;
object-fit: cover;
@media screen and (max-width: 768px) {
    width: 150px;
    height: 150px;
}
`

export const Introspace = styled.div`
height: 30px;
`
;