import React from 'react';
import { FaqBlock, FaqContainer, FaqQ, FaqA, FaqTitleB, FaqTitle, Faqspace } from './FaqElements';

const Faq = () => {
    return (
        <>
        <FaqContainer id="faq">
            <FaqTitleB>
                <FaqTitle>FAQ</FaqTitle>
                <hr/> <br/>
            </FaqTitleB>
            <Faqspace/>
            <FaqBlock>
                <FaqQ>
                What is the mint date ?
                </FaqQ>
                <br/>
                <FaqA>
                TBC
                </FaqA>
            </FaqBlock>

            <FaqBlock>
                <FaqQ>
                What will the price be ?
                </FaqQ>
                <br/>
                <FaqA>
                    Presale Price: 0.04ETH
                </FaqA>
                <FaqA>
                    Public Sale Price: 0.05ETH
                </FaqA>
            </FaqBlock>

            <FaqBlock>
                <FaqQ>
                How many NFT can I mint ?
                </FaqQ>
                <br/>
                <FaqA>
                Pre-Saler will be able to mint 3 NFTs. The Public sale has no limit but a maximum of 10 NFT per transaction.
                </FaqA>
            </FaqBlock>
        </FaqContainer>
        </>
    )
}

export default Faq