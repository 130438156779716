import styled from "styled-components";

export const MissionContainer = styled.div`
background: #008EFF;
flex-direction: column;
height: 900px;
display: flex;
`

export const Missionspace = styled.div`
height: 30px;
`

export const MissionTitle = styled.div`
padding: 0 9%;
flex-direction: column;
align-items: center;
text-align: center;
`

export const MissionContentH1 = styled.h1`
color: #fff;
font-size: 50px;
font-family: 'Bungee Shade', cursive;
`

export const MissionBlock = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

export const MissionImg = styled.img`
width: 60%;
height: auto;
display: flex;
object-fit: cover;
@media screen and (max-width: 768px) {
    width: 80%;
}
`

export const MissionContentP = styled.p`
color: #fff;
margin-top: 24px;
font-size: 24px;
padding: 0 9%;
@media screen and (max-width: 768px) {
    font-size: 22px;
}
`

export const Missionhashtag = styled.p`
color: #7FE80F;
font-size: 24px;
font-family: 'Vast Shadow', cursive;
`
