import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'

export const Nav = styled.nav`
background: ${({scrollNav}) => (scrollNav ? '#0d0d0d' : '#101522')};
height: 80px;
z-index = 1;
display: flex;
justify-content: center;
align-items: center;
font-size: 1rem;
position: sticky;
top: 0;
z-index: 10;

@media screen and (max-width: 960px) {
    height: 80px;
    transition: 0.8s all ease;
}

@media screen and (max-width: 768px) {
    height: 60px;
    transition: 0.8s all ease;
}

`;

export const NavbarContainer = styled.div`
display: flex;
justify-content: space-between;
height: 80px;
z-index: 1;
width: 100%;
padding: 0 24px;
max-width: 1100px;

@media screen and (max-width: 960px) {
    height: 80px;
}

@media screen and (max-width: 768px) {
    height: 60px;
}

`;

export const NavLogo = styled(LinkR)`
color: #fff;
justify-self: flex-start;
cursor: pointer;
font-size: 1.5rem;
display: flex;
align-items: center;
margin-left: 24px;
font-weight: bold;
text-decoration: none;
`;

export const NavLogImg = styled.img`
align-items: center;
max-width: 300px;
height: auto;
@media screen and (max-width: 960px) {
    max-width: 250px;
}

@media screen and (max-width: 768px) {
    max-width: 200px;
}


`

export const MobileIcon = styled.div`
display: none;

@media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pinter;
    color: #fff;
}
`
export const NavMenu =styled.ul`
display:flex;
align-items: center;
list-style: none;
text-align: center;
margin-right: -22px;

@media screen and (max-width: 768px) {
    display: none;
}
`;

export const NavItem = styled.li`
height: 80px;
`;

export const NavLinks = styled(LinkS)`
color: #fff;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;

&:hover {
    color: #01bf71;
}

&.active {
    border-buttom: 3px solid #01bf71;
}
`;

export const SocialIcons = styled.div`

height: 80px;

`;

export const SocialIconLink = styled.a`
color: #fff;
font-size: 24px;
display: flex;
align-items: center;
padding: 0 1rem;
`
;