import React from 'react';
import { RoadmapBlock, RoadmapContainer, Roadmapgif, RoadmapgifB, RoadmapH, Roadmapitem, RoadmapitemB, Roadmaplink, RoadmapP, Roadmapspace, RoadmapTopic } from './RoadmapElements';
import egg from '../../images/Egg.gif';
import turtle from '../../images/turtle.gif';
import ht from '../../images/HT2.gif';
import helmetT from '../../images/70.png';

const Roadmap = () => {
    return (
        <>
        <RoadmapContainer id="roadmap">
            <RoadmapTopic>
                <RoadmapH>
                    RoadMap
                </RoadmapH>
                <hr/> <br/>
                <Roadmapspace/>
                <RoadmapP>
                We are enough with plastic. Therefore the physical merch may not be considered at this moment.
                </RoadmapP>
            </RoadmapTopic>
            <Roadmapspace/>
            <RoadmapBlock>
                <RoadmapgifB>
                    <Roadmapgif src={egg}/>
                </RoadmapgifB>
                <RoadmapitemB>
                    <Roadmapitem>
                    Donation - 25% of the sales will be donated. 
                    </Roadmapitem>
                    <br/>
                    <Roadmapitem>
                    The Nature Conservancy and Oceana which we are planning to donate. Because of the financial transparency and efficiency.
                    </Roadmapitem>
                    <br/>
                    <Roadmaplink href="//www.nature.org/en-us/" target="_blank">The Nature Conservancy</Roadmaplink>
                    <Roadmaplink href="//www.oceana.org/" target="_blank">Oceana</Roadmaplink>
                </RoadmapitemB>
            </RoadmapBlock>
            <Roadmapspace/>
            <RoadmapBlock>
                <RoadmapgifB>
                    <Roadmapgif src={ht}/>
                </RoadmapgifB>
                <RoadmapitemB>
                    <Roadmapitem>Helmet Turtles takes a 10% royalty of secondary sales.</Roadmapitem>
                    <br/>
                    <Roadmapitem>We will keep donating half of royalty by monthly/ bi-monthly.</Roadmapitem>
                    <br/>
                    <Roadmapitem>A quarter of royalty will go into club wallet. and other quarter will support our team development.</Roadmapitem>
                </RoadmapitemB>
            </RoadmapBlock>
            <Roadmapspace/>
            <RoadmapBlock>
                <RoadmapgifB>
                    <Roadmapgif src={turtle}/>
                </RoadmapgifB>
                <RoadmapitemB>
                    <Roadmapitem>
                    DAO for Helmet Turtles (HT) Club, Holders vote on what environmental projects and NGO to support.
                    </Roadmapitem>
                    <br/>
                    <Roadmapitem>
                    HT Fund will be established to help our community members
                    </Roadmapitem>
                </RoadmapitemB>
            </RoadmapBlock>
            <RoadmapBlock>
                <RoadmapgifB>
                    <Roadmapgif src={helmetT}/>
                </RoadmapgifB>
                <RoadmapitemB>
                    <Roadmapitem>
                    Hire talented turtles for club growth. Development on metaverse. 
                    </Roadmapitem>
                    <br/>
                    <Roadmapitem>
                    Engaging with NGO to help raise awareness and push for further momentum through metaverse events.
                    </Roadmapitem>
                </RoadmapitemB>
            </RoadmapBlock>

        </RoadmapContainer>
        </>
    )
}
export default Roadmap