import React from 'react';
import { MissionBlock, MissionContainer, MissionContentH1, Missionhashtag, MissionContentP, MissionImg, Missionspace, MissionTitle } from './MissionElements';
import Missionimg from '../../images/Banner3.png';

const Mission = () => {
    return (
        <>
        <MissionContainer id="mission">
            <MissionTitle>
                <MissionContentH1>Mission</MissionContentH1>
                <hr/><br/>
            </MissionTitle>
            <Missionspace/>
            <MissionBlock>
                <MissionImg src={Missionimg} />
                <MissionContentP>Turtles need to fight back</MissionContentP>
                <MissionContentP>
                Our 5 species are now endangered because of human activities. 
                </MissionContentP>
                <MissionContentP>
                My cousins "Hawksbill" are critically endangered, they have been hunted by poachers pursuing their beautiful shells that are made into tortoiseshell combs, eyeglasses, trinkets, and more.
                </MissionContentP>
                <MissionContentP>
                We are enough with any kind of threat. We are enough with pollution. We go into metaverse to raise all creatures awareness of environmental issues.
                </MissionContentP>
                <MissionContentP>
                Get a helmet on and save ourselves. Save our family.
                </MissionContentP>
                <Missionspace/>
                <Missionhashtag>#TurtleSaveTurtles</Missionhashtag>
            </MissionBlock>
        </MissionContainer>
        </>
    )
}

export default Mission