import React from 'react';
import { Introborder, Introborder2, IntroContainer, IntroContent, IntroContentH1, IntroContentP, IntroImg, IntroSlogan, Introspace } from './IntroElements';
//import Intropic from '../../images/HT.gif';
import Intropic from '../../images/ht_gif.gif';

const Intro = () => {
    return (
        <>
        <IntroContainer id="home">
            <Introborder>
                <IntroContent>
                    <IntroContentH1>Helmet Turtles</IntroContentH1>
                    <Introspace/>
                    <IntroContentP>7,788 unique generated turtles wearing helmets and getting out of the danger zone. They step into ETH blockchain to survive.</IntroContentP>
                    <Introspace/>
                    <IntroSlogan>Turtle wants to Survive!!</IntroSlogan>
                </IntroContent>
            </Introborder>
            <Introborder2>
                <IntroImg src={Intropic}/>
            </Introborder2>

        </IntroContainer>
        </>
    )
}

export default Intro