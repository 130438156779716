import styled from "styled-components";

export const FaqContainer = styled.div`
background: #008EFF;
flex-direction: column;
height: 900px;
display: flex;

@media screen and (max-width: 768px) {
    height: 800px;
}
`

export const Faqspace = styled.div`
height: 30px;
`

export const FaqTitleB = styled.div`
height: 80px;
padding: 0 9%;
flex-direction: column;
align-items: center;
text-align: center;
justify-content: center;

`

export const FaqTitle = styled.h1`
color: #fff;
font-size: 50px;
font-family: 'Bungee Shade', cursive;
`

export const FaqBlock = styled.div`
flex-direction: column;
height: 150px;
display: flex;
padding: 0 9%;

`

export const FaqQ = styled.h1`
color: #0E7300;
font-size: 32px;
@media screen and (max-width: 768px) {
    font-size: 24px;
}
`

export const FaqA = styled.p`
color: #fff;
font-size: 24px;
@media screen and (max-width: 768px) {
    font-size: 22px;
}
`