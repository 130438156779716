import React from 'react'
import { animateScroll as scroll} from 'react-scroll'
import footerlogo from '../../images/weblogo.png'
import { FaDiscord, FaInstagram, FaTwitter } from 'react-icons/fa'
import { 
    FooterContainer, 
    FooterWrap, 
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    FooterLogo,
    SocialIcons,
    SocialIconLink,
    WebsiteRights
} from './FooterElements'

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop()
    };

    return (
        <FooterContainer>
            <FooterWrap>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>
                            <FooterLogo src={footerlogo}/>
                        </SocialLogo>
                        <WebsiteRights>© {new Date().getFullYear()} All Rights Reserved</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="//discord.com/invite/5cscNt3tGs" target="_blank" aria-label="Discord">
                                <FaDiscord />
                            </SocialIconLink>
                            <SocialIconLink href="//www.instagram.com/helmet_turtles/" target="_blank" aria-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href="//twitter.com/Helmet_Turtles/" target="_blank" aria-label="Twittwer">
                                <FaTwitter />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
