import styled from "styled-components";

export const HomeContainer = styled.div`
display: flex;
overflow: hidden;
position: relative;
z-index: 1;

`

export const HomeImg = styled.img`
width: 100%;
height: 100%;
object-fit: cover;

`